var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { padding: "40px" } },
    [
      _c("h2", [
        _c(
          "span",
          {
            attrs: {
              id: "health-page-heading",
              "data-cy": "healthPageHeading",
            },
          },
          [_vm._v("Health Checks")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary float-right",
            attrs: { disabled: _vm.updatingHealth },
            on: {
              click: function ($event) {
                return _vm.refresh()
              },
            },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "sync" } }),
            _vm._v(" "),
            _c("span", [_vm._v("Refresh")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "table-responsive" }, [
        _c(
          "table",
          {
            staticClass: "table table-striped",
            attrs: { id: "healthCheck", "aria-describedby": "Health check" },
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.healthData, function (health) {
                return _c("tr", { key: health.name }, [
                  _c("td", [
                    _c("span", { staticClass: "text-capitalize" }, [
                      _vm._v(_vm._s(_vm.baseName(health.name))),
                    ]),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.subSystemName(health.name)) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    _c(
                      "span",
                      {
                        staticClass: "badge",
                        class: _vm.getBadgeClass(health.status),
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(health.status) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    health.details || health.error
                      ? _c(
                          "a",
                          {
                            staticClass: "hand",
                            on: {
                              click: function ($event) {
                                return _vm.showHealth(health)
                              },
                            },
                          },
                          [_c("font-awesome-icon", { attrs: { icon: "eye" } })],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
              0
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "healthModal" },
        [
          _vm.currentHealth
            ? _c(
                "h4",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title", id: "showHealthLabel" },
                  slot: "modal-title",
                },
                [
                  _c("span", { staticClass: "text-capitalize" }, [
                    _vm._v(_vm._s(_vm.baseName(_vm.currentHealth.name))),
                  ]),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.subSystemName(_vm.currentHealth.name)) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("health-modal", {
            attrs: { "current-health": _vm.currentHealth },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Service Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Status"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", attrs: { scope: "col" } }, [
          _vm._v("Details"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }