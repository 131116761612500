var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "modal-body pad" }, [
    _vm.currentHealth && _vm.currentHealth.details
      ? _c("div", [
          _c("h5", [_vm._v("Properties")]),
          _vm._v(" "),
          _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "Health" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.currentHealth.details.details,
                    function (item, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(index)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _vm._v(_vm._s(_vm.readableValue(item))),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.currentHealth && _vm.currentHealth.error
      ? _c("div", [
          _c("h4", [_vm._v("Error")]),
          _vm._v(" "),
          _c("pre", [_vm._v(_vm._s(_vm.currentHealth.error))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", attrs: { scope: "col" } }, [
          _vm._v("Name"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", attrs: { scope: "col" } }, [
          _vm._v("Value"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }